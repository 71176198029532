<template>
	<div class="page">
		<el-container>
			<el-main>
				<el-card class="box-card" shadow="never" style="min-height: 312px;">
					<p v-if="!hasReferrer">{{$t('invite_des')}} </p>
					<template v-if="hasReferrer">
						<p style="margin-top: 30px; font-size: 14px;">{{$t('ReferralLink')}}：</p>
						<div style=" margin-bottom: 30px;">
							<p class="copyInviteLink" style="">{{ copyInviteLink }}</p>
							<div style="margin: 0 auto;width: 100px;">
								<el-button type="primary" class="copy-btn" plain size="medium" v-clipboard="copyInviteLink" v-clipboard:success="clipboardSuccessHandler">{{$t('Copy')}}</el-button>
							</div>

						</div>
					</template>
					<!-- <div class="item" style="margin-bottom: 20px;">
						<div class="dropdown nav-item">
							<div class="label dropdown-toggle" data-toggle="dropdown">{{$t('Inviteequantity')}}<i class=" fa"></i></div>
							<div class="dropdown-menu">
								<p v-for="(item,i) in referree" :key="i">{{item}}</p>
							</div>
						</div> 
						<div class="value">{{referreeCount}}</div>
					</div> -->
				</el-card> 
			</el-main>
		</el-container>
	</div>
</template>
<script>
	import {
		ethers
	} from 'ethers';
	import axios from 'axios'; 
	let provider;
	import contract_address from '@/common/config/contractAddress.js';
	import MiningPool from '@/common/config/MiningPool.json';
	import Token from '@/common/config/ERC20.json'; 
	let MiningPoolContract;
	import {
		useContract
	} from '@/common/api/core.js'
	import deployments from '@/common/deployments.json';
	import {
		ready,
		getProvider,getWalletAddress,Contract,getWallet
	} from '@/common/ethersConnect'
	
	export default {
		data() {
			let inviteCode = localStorage.getItem('address');
			var len = inviteCode.length;
			var x = inviteCode.substring(4, len - 4);
			let shortInviteCode = inviteCode.replace(x, '***');
			return {
				hasReferrer: false, //是否绑定
				inviteLink: window.location.origin + '/#/?code=' + shortInviteCode,
				copyInviteLink: window.location.origin + '/#/?code=' + inviteCode,
				referree: [],
				referreeCount: 0, //我的直接推广人数
				referReward: '-', //我的总推广奖励 
				invite_list: [],
				// priceLast: 0
			};
		},
		
		computed: {
			address() {
				return this.$store.state.address;
			}
		},
		watch: {
			address(val) {
				console.log("获取地址数据", val);
				if (val) {
					this.getData();
				}
			}
		},
		mounted() {
			this.getData();
		},
		filters:{
			my_address(value){
				var len = value.length;
				var x = value.substring(2, len -4);
				var values = value.replace(x, '***');
				return values;
			}
		},
		methods: {
			async getData() {
				if(!ready()) return false;
				console.log('邀请界面获取数据');
				//钱包模型
				// provider = this.$store.state.provider;
				// let address = this.$store.state.address;
				// let provider2 = new ethers.providers.Web3Provider(provider);
				// const wallet = provider2.getSigner();
				MiningPoolContract = useContract('Refer');
				 
				MiningPoolContract.hasReferer(this.address).then(res => {
					console.log(res);
					if (res) {
						this.hasReferrer = true;
					}
				})
				// //获取邀请数据 
				// let referReward = await MiningPoolContract.referReward(this.address);
				// this.referReward = ethers.utils.formatUnits(referReward);

				// let count = await MiningPoolContract.referreeCount(this.address);
				// this.referreeCount = count.toString();
				// //邀请明细
				// for (var i = 0; i < this.referreeCount; i++) {
				// 	var user = await MiningPoolContract.referree(this.address, i);
				// 	this.referree.push(user);
				// }  
			},
			//复制到剪贴板
			clipboardSuccessHandler({
				value,
				event
			}) {
				console.log('success', value);
				this.$message.success('已复制');
			},
		}
	};
</script>
<style lang="scss">
	@import "/src/uni.scss";
	.page {
		padding: 15px 0px; //整个界面
	}

	.copyInviteLink {
		//邀请链接
		font-size: 14px;
		margin-top: 15px;
		color: $u-type-success-dark;
		word-wrap: break-word;
		word-break: break-all;
	}

	.copy-btn {
		border: 1px solid $u-type-success-dark;
		margin-left: 10px;
	}

	.box-card {
		h3 {
			color: $u-type-success-dark;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
		}

		.item {
			display: flex;
			line-height: 30px;
			justify-content: space-between;
			// margin-top: 40px;

			.label {
				color: #666;
				font-size: 16px;
			}

			.value {
				font-size: 14px;
				color: #333;
				font-weight: bold;
			}
		}

		.el-dialog__body {
			text-align: center;
		}
	}

	.el-main {
		padding: 0px;
	}

	@media (min-width: 1200px) {
		.box-card {
			width: 350px;
			margin: 0 auto;
		}

		.el-main {
			padding: 20px;
		}
	}
</style>
